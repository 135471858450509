.transfer_modal_overlay {
  .css-tlc64q-MuiPaper-root-MuiDialog-paper {
    min-height: calc(100% - 64px);
  }
}

.create-transfer {
  margin: 0 25px 0 25px;
  position: relative;
  min-height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &_search {
    width: 100%;
    border: 1px solid #959595;
    padding: 7px 14px;
    height: 40px;
    box-sizing: border-box;
  }

  &_title {
    gap: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &_form {
    margin-bottom: 20px;

    .origin_label {
      text-transform: uppercase;
      color: #000000;
      font-size: 12px;
      pointer-events: none;
      margin-top: 15px;
      font-weight: 500;
    }
  }

  .search-type-switchers {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }

  .type-switcher {
    transition: none;
  }

  .continue-btn {
    width: 180px;
    margin: auto;
  }

  .chosen-collector {
    position: sticky;
    padding: 10px 0;
    bottom: 0;
    border-top: 1px solid #dddddd;
    display: flex;
    gap: 10px;
    flex-direction: column;
    height: fit-content;
    background: #fff;
    width: 100%;
    z-index: 1000;
  }
}
