.collected_by_user {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 15px;
  cursor: pointer;

  &__text {
    margin-right: 5px;
  }

  &__avatar {
    margin-right: 5px;
  }
}
