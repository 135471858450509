@import "../../styles/variables.scss";

.profile_shows {
  &__title {
    &:first-child {
      margin-top: 25px;
    }
    font-size: $title_h2;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
  }

  &__host {
    margin-bottom: 25px;
  }
  .institution_card {
    width: 100%;
  }

  &__back_button {
    margin-right: 10px;
  }
}