.transfer-details {
  margin: 0 25px 15px 25px;

  &_content {
    margin-top: 20px;
  }

  &_title {
    gap: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &_buttons {
    display: flex;
    flex: content;
    justify-content: space-between;
    margin-top: 25px;

    * {
      width: 48%;
    }
  }

  &_images {
    position: relative;
    margin-bottom: 15px;

    .slider_arrow_button {
      height: 100%;
      color: #000000;
    }

    .prev {
      left: -16px;
    }

    .next {
      right: -23px;
    }

    &_image {
      max-height: 200px;
      object-fit: cover;
    }
  }

  .origin_label {
    text-transform: uppercase;
    color: #000000;
    font-size: 12px;
    pointer-events: none;
  }

  .register_item {
    margin-bottom: 10px;
  }

  .css-1480iag-MuiInputBase-root-MuiInput-root:before {
    border-bottom: 1px solid rgb(198, 198, 198) !important;
  }

  .MuiInput-root:hover:not(.Mui-disabled)::before {
    border-bottom: 1px solid rgb(198, 198, 198) !important;
  }
  
  .MuiInput-root::after {
    border-bottom: none;
  }
}

.leaflet-top,
.leaflet-bottom {
  z-index: 800 !important;
}

.MuiModal-root {
  z-index: 900 !important;
}

.MuiDialogTitle-root {
  padding-top: 8px !important;
}
