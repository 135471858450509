@import "../../../../styles/variables";

.search_modal {
  position: absolute;
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: $box_shadow;
  padding-top: 24px;
  padding-bottom: 27px;
  width: 100%;
  top: 47px;
  z-index: 999;
  max-height: 450px;
  overflow-y: scroll;

  &__all__results {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 20px;
    border-bottom: 2px solid #E0E0E1;
  }

  &__button__all {
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
  }

  &__list__wrap {
    padding-top: 15px;
    border-bottom: 2px solid #E0E0E1;
  }

  &__list__title {
    font-weight: 500;
    font-size: 14px;
    padding-left: 24px;
    padding-right: 24px;
  }

  &__list__item {
    display: block;
    font-size: 14px;
    margin-top: 2px;
    padding: 13px 24px;
    cursor: pointer;
    transition: background-color .35s;

    &:hover {
      background-color: #F7F7F7;
    }
  }
}
