.filter_results__content {
  display: flex;
  align-items: center;

  .filter_results__left_content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    grid-row-gap: 15px;
  }

  .filter_results__result {
    margin-right: 15px;
  }
}
