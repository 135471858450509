.suggestion {
  display: flex;
  font-size: 14px;
  margin-bottom: 20px;
  max-width: 615px;

  &__avatar {
    margin-right: 10px;
    cursor: pointer;
  }

  &__author_name {
    margin-bottom: 5px;
    font-weight: 500;
    cursor: pointer;
  }

  &__info {
    width: 100%;
  }

  &__text_info {
    margin-bottom: 5px;
  }

  &__notes {
    color: gray;
    margin-bottom: 5px;
  }

  &__video {
    max-width: 300px;
  }

  &__remove_button {
    align-self: center;
  }
}
