@import "../../styles/variables";

.edit_origin_of_inspiration {
  padding-top: 25px;
  margin: 0 auto;
  width: 50%;
  padding-bottom: 25px;

  .origin_title__wrap {
    position: relative;

    .arrow__back {
      font-size: 40px;
      top: 20px;
      left: -50px;
    }

    .origin_title {
      font-size: $title_h2;
    }

    .origin_sub_title {
      font-size: $title_h6;
    }
  }

  .origin_grid {
    position: relative;
    margin-top: 30px;
  }

  .origin_item {
    margin-bottom: 25px;
    position: relative;
  }

  .origin_label {
    text-transform: uppercase;
    color: $color_black;
    font-size: 12px;
    font-weight: 500;
  }

  .text_field {
    width: 100%;

    input {
      font-size: 15px;
      background-color: transparent !important;
    }
  }

  .MuiInput-root::before {
    border-bottom: 1px solid $color_grey;
  }
  .MuiInput-root::after {
    border-bottom: 2px solid $color_grey;
  }
  .MuiInput-root:focus::after {
    border-bottom: 2px solid $color_grey;
  }

  .MuiInput-root.Mui-error::after {
    border-bottom: 2px solid $color_error;
  }

  .MuiInput-root:hover:not(.Mui-disabled)::before {
    border-bottom: 2px solid $color_grey;
  }

  .MuiInputLabel-root {
    &.Mui-error.Mui-focused {
      color: $color_error;
    }
    &.Mui-focused {
      color: $color_light_black;
    }
  }
}
