@import "../../styles/variables";

.artwork_list_item {
  margin-bottom: 25px;
}
.artwork_list_item__icon__wrap {
  position: relative;

  .artwork_list_item__icon {
    width: 100%;
    cursor: pointer;
  }
}

.artwork_list_item__info {
  margin-top: 15px;
  overflow: hidden;
  text-overflow: ellipsis;

  .artwork_list_item__owner {
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    white-space: nowrap;
  }

  .artwork_list_item__title {
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 4px;
  }

  .artwork_list_item__price {
    color: $color_black;
    margin-top: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }
}
