.button_top {
  position: fixed;
  bottom: 50px;
  cursor: pointer;
  transition: all .35s ease;
  z-index: 9999;
  right: -50px;
  &.show {
    right: 50px;
  }
  &:hover {
    opacity: .6;
  }
}