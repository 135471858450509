.modal_download {
  .modal_download__image {
    text-align: center;
    margin-bottom: 15px;
  }

  .download_moca__text {
    text-align: center;
    text-transform: capitalize;
    font-weight: 600;
  }

  .app_store__button {
    width: 100px;
  }

  .google_play__button {
    width: 115px;
  }
}
