@import "../../styles/variables";
.blocked-users {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;

  &_container {
    width: 100%;

    &__wrapper {
      width: 40%;
      margin: 0 auto;
    }
  }

  &_title {
    font-size: 30px;

    &__wrap {
      position: relative;
    }
  }

  .arrow__back {
    font-size: 40px;
    top: 20px;
    left: -50px;
  }

  .artist_item__description {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
