.select_sort {
  background-color: rgba(229, 229, 229, 0.3);
  .MuiSelect-icon {
    display: none;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .MuiSelect-select {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
  }
}
