.moca_comment__body {
  display: flex;
}

.moca_comment {
  margin-top: 17px;

  .like {
    position: absolute;
    top: 15px;
    right: 0;
  }

  &__author {
    display: flex;
    flex-grow: 1;
  }

  &__author__grid {
    position: relative;
    flex-grow: 1;
    margin-left: 12px;
  }

  &__name {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-top: 5px;
    width: calc(100% - 25px);
    white-space: pre-line;
  }

  &__time, &__btn {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #A3A0A0;
  }
}

.moca_reply__wrap {
  padding-left: 20px;
  margin-top: 10px;
}