@import "../../styles/variables.scss";

.report_dialog {
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.9);;
  }
  .MuiPaper-root {
    padding: 10px;
    width: 350px;
  }
  .report_dialog__close_button {
    position: absolute;
    cursor: pointer;
    & path {
      fill: $color_black;
    }
  }

  .MuiDialogTitle-root {
    text-align: center;
    padding-top: 0;
  }

  .report_dialog__content {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  .report_dialog__report_button {
    align-self: center;
    margin-top: 20px;
    width: 100%;
  }
  .report_label {
    text-transform: uppercase;
    color: #000000;
    font-size: 12px;
    font-weight: 500;
  }

  .MuiNativeSelect-select {
    &:focus {
      background-color: #ffff;
    }
    &:after{
      border-bottom: none;
    }
  }

  .css-a88p61-MuiInputBase-root-MuiInput-root:after {
    border-bottom: none;
  }

  .report_dialog__input {
    margin-top: 10px;
  }
}
