.basic_select {
  .origin_select {
    width: 100%;
  }

  .basic_select__label {
    font-weight: 500;
  }

  .MuiInput-root::after {
    border-bottom: 2px solid #9d9ea1;
  }

  .MuiInput-root:hover:not(.Mui-disabled)::before {
    border-bottom: 2px solid #9d9ea1;
  }
}
