@import "../../styles/variables.scss";

.explore_page_title {
  margin-bottom: 32px;

  .MuiTooltip-tooltip {
    line-height: 20px;
    max-width: 500px;
    min-width: 400px;
  }

  &__moca {
    font-size: $title_h5;
    margin-bottom: 9px;
    line-height: 16px;
    color: $title_color;
    font-weight: 500;
    font-style: normal;
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    color: $title_color;
    position: relative;

    .back-button {
      margin-right: 10px;
    }
  }

  &__grid {
    display: flex;
    grid-column-gap: 30px;
    align-items: center;
  }

  .question_mark_item {
    position: absolute;
    top: -5px;
    margin-left: 10px;
    cursor: pointer;
    right: -30px;
  }

  .tooltip_item {
    padding-left: 10px;
  }
}
