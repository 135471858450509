.filter_origins_of_inspiration {
  &__input {
    cursor: text;
    display: flex;
    align-items: center;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    border: 1px solid rgb(229, 232, 235);
    padding: 6px 2px;
    margin-top: 10px;
    position: relative;
  }

  &__input_base {
    width: 100%;
    padding-left: 5px;
  }

  &__clear {
    cursor: pointer;
  }
}
