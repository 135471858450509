@import "../../styles/variables";

.origin_of_inspiration {
  padding-top: 50px;

  .origin_sub_title {
    font-size: $title_h6;
  }

  .origin_title__wrap {
    margin: 0 auto;
    width: 50%;
    position: relative;

    .arrow__back {
      font-size: 40px;
      top: 20px;
      left: -50px;
    }

    .origin_title {
      font-size: $title_h2;
    }
  }

  .origin_grid {
    position: relative;
    margin-top: 15px;
  }

  .next_page {
    right: 0;
    position: absolute;
  }

  .origin_item__add {
    width: 97%;
  }

  .origin_item {
    margin-bottom: 25px;
    position: relative;
  }

  .origin_label {
    text-transform: uppercase;
    color: $color_black;
    font-size: 12px;
  }

  .add_icon {
    position: absolute;
    cursor: pointer;
    font-size: 40px;
  }

  .origin_select {
    width: 100%;
  }

  .origin_input_phone {
    width: 100%;
    border-top: transparent;
    border-left: transparent;
    border-right: transparent;
    border-radius: 0;
    padding: 0 10px 10px;
    box-sizing: border-box;
    &::before {
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
      left: 0;
      bottom: 0;
      content: "\00a0";
      position: absolute;
      right: 0;
      -webkit-transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      pointer-events: none;
      &:hover {
        border-bottom: 2px solid rgba(0, 0, 0, 0.87);
      }
    }
  }

  .origin_sub_title {
    color: $color_grey;
  }

  .text_field {
    width: 100%;

    input {
      font-size: 15px;
      background-color: transparent !important;
    }
  }

  .text_area {
    width: 100%;
    &:hover::before {
      border-bottom: 2px solid rgba(0, 0, 0, 0.87);
    }
    &:focus::before {
      border-bottom: 2px solid #1976d2;
    }
    &::before {
      content: "";
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
      left: 0;
      bottom: 0;
      position: absolute;
      right: 0;
      -webkit-transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      transition: border-bottom -color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      pointer-events: none;
    }
  }

  .login_button {
    width: 250px;
    margin: 0 auto;
    display: block;
  }

  .MuiInput-root::before {
    border-bottom: 1px solid  $color_grey;
  }
  .MuiInput-root::after {
    border-bottom: 2px solid  $color_grey;
  }
  .MuiInput-root:focus::after {
    border-bottom: 2px solid  $color_grey;
  }

  .MuiInput-root.Mui-error::after {
    border-bottom: 2px solid  $color_error;
  }

  .MuiInput-root:hover:not(.Mui-disabled)::before {
    border-bottom: 2px solid  $color_grey;
  }

  .MuiInputLabel-root {
    &.Mui-error.Mui-focused {
      color: $color_error;
    }
    &.Mui-focused {
      color: $color_light_black;
    }
  }

  .upload_image__footer {
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 60px;
    align-items: center;
    border-top: 3px solid #E0E0E1;
    height: 70px;
    padding: 0 58px;
  }

  .upload_image__link {
    font-weight: 600;
    text-decoration: underline;
  }
}