.slider_arrow_button {
  border: none;
  background: none;
  cursor: pointer;
  height: 100vh;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 9999;
  &:disabled {
    display: none;
  }
}

.next {
  right: 0;
}

.prev {
  left: 0;
  color: #fff;
}
