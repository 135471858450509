.login_drop__menu {
  overflow: visible;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32));

  & .MuiAvatar-root {
    width: 32px;
    height: 32px;
    margin-left: 5px;
    margin-right: 10px;
  }
}

.your_account {
  font-size: 15px;
  font-weight: 500;
  margin-left: 16px;
  padding-top: 16px;
  padding-bottom: 10px;
}

.account {
  font-size: 15px;
  width: 364px;

  &_avatar {
    display: flex;
    align-items: center;
    margin-left: 16px;
    padding-bottom: 16px;
  }

  &_avatar__edit {
    margin-top: 5px;
    cursor: pointer;
  }

  &_name {
    font-weight: 500;
    cursor: pointer;
  }
  & .MuiAvatar-circular.MuiAvatar-root {
    width: 50px;
    height: 50px;
  }

  & .controller_list__title {
    font-size: 16px;
    margin: 16px 0 6px 16px;
    max-width: 300px;
    font-weight: 500;
  }

  & .controller_list__item {
    display: block;

    a {
      font-size: 15px;
      display: block;
      font-weight: 400;
    }
  }

  .privacy_terms {
    margin-left: 16px;
    padding-bottom: 16px;
    padding-top: 8px;
  }

  .controller_nav__item {
    font-weight: 500;
  }

  .my_avatar {
    cursor: pointer;
  }
}

.MuiList-root .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.controller_logout {
  font-weight: 500;
  cursor: pointer;
  margin-top: 8px;
}

.MuiList-root .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  cursor: auto;
}