@import "../../styles/variables";

.institution_card {
  display: block;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-top: none;
  border-radius: 0 0 10px 10px;
  box-sizing: border-box;
  transition: box-shadow 0.3s;
  margin-bottom: 12px;
  cursor: pointer;
  width: calc(25% - 9px);

  &:hover {
    box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.25);
  }

  .institution_card__image {
    height: 200px;
    overflow: hidden;
    background-color: $imagePlaceholderColor;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .institution_card__open_to_submission {
      position: absolute;
      background-color: $color_black;
      color: $color_white;
      bottom: 0;
      left: 0;
      padding: 4px;
    }
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: top;
  }

  &__MOCA {
    font-size: 24px;
    color: #0000001f;
  }

  .institution_card__body {
    padding: 10px;
    width: 100%;
    box-sizing: border-box;

    .institution_card__name {
      font-size: 18px;
      font-weight: 600;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .institution_card__description {
      margin-top: 10px;
      font-weight: 400;
      color: #808080;
    }

    .institution_card__location {
      color: $color_grey;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .institution_card__open_to_submission {
      margin: 10px 0;
    }

    .institution_card__duration {
      display: flex;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 14px;

      .institution_card__duration__time {
        margin-left: 5px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
