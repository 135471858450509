@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import "styles/variables";

a {
  text-decoration: none;
  color: var(--color_black);
}

* {
  margin: 0;
  padding: 0;
  outline: none;
  list-style: none;
  font-family: $fonts !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.comments_title {
  font-size: 18px;
  margin-bottom: 10px;
}

.wrapper {
  max-width: 1600px;
  padding: 0 20px;
  margin: 0 auto;
  box-sizing: border-box;
}

.App {
  display: flex;
  flex-direction: column;
}

.list_line {
  border-bottom: 5px solid #e1e6ec;
}

.icon-wrap {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #aba6a0;
  cursor: pointer;
}

.icon-wrap img {
  width: 70%;
}

.ReactModal__Overlay.modal_custom {
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
}

.google_map {
  height: 250px;
  width: 100%;
}

.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  background-color: red;
  padding: 20px;
}

.artwork_item {
  cursor: pointer;
  margin-bottom: 12px;
  position: relative;

  .artwork_item__image {
    width: 100%;
    object-fit: cover;
  }

  .artwork_item__votes {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color_black;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #779C42;
    position: absolute;
    bottom: 8px;
    right: 5px;
  }
}

.download_moca__link {
  border: none;
  cursor: pointer;
}

.MuiButton-root {
  &.login_button {
    background-color: $color_black;

    &:hover {
      background-color: $color_light_black;
    }
  }
}

.MuiFormControl-root {
  &.input {
    width: 100%;

    .MuiInputLabel-root {
      color: #000000;
    }
  }
}

.login_button {
  margin-top: 20px;
  width: 100%;
}

.error_message {
  font-size: 12px;
  color: #fa2d2d;
  margin-top: 5px;
}

.info_message {
  font-size: 12px;
  color: #696969;
  margin-top: 5px;  
}

.explore_navigation {
  display: flex;
  margin-bottom: 27px;
  border-bottom: 2px solid #e0e0e1;
}

.artworks_list {
  gap: 12px;
}

.explore_grid {
  display: flex;

  .left_side__bar {
    width: 0px;

    .artworks_item__side-bar {
      position: -webkit-sticky;
      position: sticky;
      top: 0;

      .filter_arrow__item {
        margin-left: 10px;
        display: none;
      }
    }
  }

  &.active {
    .left_side__bar {
      width: $left_side__bar;
      min-width: $left_side__bar;

      .filter_arrow__item {
        display: flex;
      }
    }
  }

  .explore_list {
    grid-area: content;
    flex-grow: 1;
    box-sizing: border-box;
    width: calc(100vw - 312px);

    .filter_results__content {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      margin-top: 14px;
      padding: 0 6px;
      min-height: 19px;

      .select_sort {
        min-width: 300px;
        margin-left: 12px;
      }
    }
  }
}

.filter_top_bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;

  &__icon {
    display: flex;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
    font-weight: 500;
  }

  .filter_icon {
    font-size: 25px;
    margin-left: 10px;
    cursor: pointer;
  }
}

.leaflet_map {
  margin-bottom: 50px;
}

.my-masonry-grid {
  display: flex;
  width: auto;
  flex-wrap: wrap;

  .my-masonry-grid_column {
    background-clip: padding-box;
    box-sizing: border-box;
    padding: 0 6px;
  }
}

.card_wrap {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 12px;
}

@media (max-width: $media_xl) {
  .wrapper {
    max-width: 1320px;
  }
}

.next_page {
  background-color: $color_black;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;

  .next_page__svg {
    fill: $color_white;
    font-size: 40px;
  }
}

.arrow__back {
  position: absolute;
  left: 0;
  transform: translateY(-50%);
  top: 50%;
  cursor: pointer;
}

.required {
  font-size: 12px;
  color: $color_grey;
  margin-top: 5px;
}

.label {
  font-weight: 600;
  text-transform: uppercase;
  color: $color_black;
}

.upload_input__file {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1000;
}

.register_photos__grid {
  display: flex;
  grid-column-gap: 25px;
}

.register_photos {
  display: flex;
  grid-column-gap: 10px;
  flex-grow: 1;
  overflow-y: scroll;
}

.register_add__photo {
  width: 150px;
}

.register_add__photo__item {
  position: relative;
  border: 1px solid $color_grey;
  border-radius: 10px;
  width: 150px;
  min-width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: box-shadow 0.35s;

  .register_photo__close {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.35s;
    display: inline-block;
    background-color: rgba(0, 0, 0, .2);

    fill: $color_white
  }

  &:hover .register_photo__close {
    opacity: 1;
  }

  &:hover {
    box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.25);
  }

  .add_photo__svg {
    font-size: 50px;
    fill: $color_grey;
  }

  .register_photos__item {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.text_area__input {
  width: 100%;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: none;
  resize: none;
}

.edit_item__location {
  border-bottom: 1px solid $color_grey;
  box-sizing: border-box;
  position: relative;

  &:hover::before {
    content: "";
    position: absolute;
    bottom: -1px;
    display: block;
    width: 100%;
    height: 2px;
    background-color: $color_black;
    z-index: 1;
  }

  .css-yk16xz-control {
    border: none;
  }

  .css-1pahdxg-control {
    border: none;
    box-shadow: none;

    &:hover {
      border: none;
      box-shadow: none;
    }
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }

  .css-1hwfws3 {
    padding: 4px 0 5px;
  }

  .css-1wa3eu0-placeholder {
    font-size: 16px;
  }
}

.message_bubble {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  width: 100px;
  height: 35px;
  background-color: #eaeaea;
  border-radius: 8px;

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    background-color: #eaeaea;
    bottom: -10px;
    right: 10px;
    clip-path: polygon(0 0, 50% 100%, 100% 0);

  }
}

.page_background {
  height: 250px;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;

  .react-parallax {
    height: 100%;
    position: relative;
  }

  .react-parallax-content {
    height: 100%;
  }

  &__title {
    height: 100%;
    font-size: 30px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color_white;
    text-align: center;
  }

  &__image {
    height: 100%;
    max-height: 600px;
    width: 100% !important;
  }

  .insideStyles {
    padding: 20px;
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
}

.profile-artworks-page {
  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    font-weight: 500;
    font-size: 32px;
  }
  &__back-button {
    margin-right: 10px;
  }
  &__no-artworks {
    text-align: center;
    color: $color_grey;
  }
}

@media (max-width: $media_md) {
  .leaflet_map {
    margin-bottom: 5px;
  }
  .explore {
    padding-top: 10px;

    .explore_navigation {
      padding: 0 10px;
      overflow-y: hidden;
      margin-bottom: 0;

      .nav {
        overflow: scroll;
        white-space: nowrap;
        height: 50px;
        display: block;
      }
    }
  }
}

@media (max-width: $media_sm) {
  .App {
    overflow-x: hidden;
  }

  .explore_list {
    padding: 20px 10px;
  }
}
