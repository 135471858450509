@import "../../styles/variables.scss";

.show_item {
  cursor: pointer;
  display: block;
  margin-bottom: 26px;

  &__image {
    height: 357px;
    overflow: hidden;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 14px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__placeholder_text {
    font-size: $title_h2;
  }

  &__open_to_submission {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    height: 14px;
  }

  &__duration_location {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
  }

  &__permanent {
    margin-right: 5px;
  }

  &__title {
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 8px;
  }

  &__location {
    margin-right: 5px;
  }

  &__duration_time {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
