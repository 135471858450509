.winner {
  margin-bottom: 50px;

  &_image {
    width: 100%;
    max-height: 165px;
    object-fit: cover;
    height: 100%;
    cursor: pointer;
  }
}

.winner_grid {
  display: grid;
  grid-column-gap: 5px;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-template-rows: 1fr;
  overflow: hidden;
}