@import "../../styles/variables";

.profile_wrapper,
.profile_counters {
  display: flex;

  .profile {
    display: flex;
    grid-gap: 28px;

    &_avatar {
      grid-area: image;
      display: flex;
      justify-content: center;
    }

    &_info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &_name {
      font-weight: 500;
      font-size: 32px;
      line-height: 38px;
      color: $title_color;
    }

    &_follow {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      color: $title_color;
      line-height: 16px;
      margin-bottom: 25px;
      margin-top: 30px;

      span {
        margin-right: 25px;
        cursor: pointer;
      }
    }

    .profile_follow_button, .profile_edit_button {
      height: 40px;
      background-color: $title_color;
      width: 146px;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
    }

    &_text {
      font-size: $title_h6;
      color: $title_color;
    }

    &_buttons {
      display: flex;
      width: 100%;
    }

    &_message_button {
      margin: 0 15px;
    }
  }
  .profile_menu_wrapper {
    display: flex;
    height: fit-content;
    align-items: center;
    margin-left: 32px;
  }
  .profile_shows__img {
    width: 0.8em;
    height: 0.8em;
    color: $color_black;
    display: flex;
  }

  .profile_location__img {
    width: 0.8em;
    height: 0.8em;
    color: $color_black;
    display: flex;
  }

  .profile_scans__img {
    width: 0.7em;
    height: 0.7em;
    color: $color_black;
    display: flex;
  }

  .profile_vote__img {
    width: 0.7em;
    height: 0.7em;
    color: $color_black;
    display: flex;
  }
  
  .profile {
    &_tv,
    &_location,
    &_shows__count,
    &_scans,
    &_vote {
      display: grid;
      grid-template-columns: 22px 1fr;
      align-items: center;
      justify-content: end;
      column-gap: 5px;
      margin-bottom: 5px;
      cursor: pointer;
    }
    &_shows__count {
      cursor: pointer;
    }
    &_icon_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_tv__img {
      width: 15px;
      height: 15px;
    }

    &_tv__text {
      font-size: $title_h6;
      color: $title_color;
    }

    &_skeleton {
      line-height: 14px;
      max-width: 300px;
    }

    &_skeleton__litle {
      line-height: 14px;
      max-width: 150px;
      width: 60%;
    }
    &_name {
      display: flex;
      justify-content: space-between;
    }
    &_likes {
      cursor: pointer;
    }
  }
}
.profile_counters {
  display: flex;
  flex-direction: column;
}
