@import "../../styles/variables.scss";

.artist_item {
  display: grid;
  align-items: center;
  padding: 10px 0;
  grid-template-columns: 45px 1fr;
  grid-column-gap: 10px;

  .artist_item__follow_button {
    color: $color_black;
    font-size: 14px;
    font-weight: 400;
  }

  .artist_item__avatar_icon {
    width: 45px;
    height: 45px;
    cursor: pointer;
  }

  &__name {
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
  }

  &__bottom_info {
    font-size: 12px;
    color: $color_grey;
  }

  &__description {
    width: 100%;

    .likes {
      font-size: 14px;
      font-weight: 400;
    }
  }

  &__name_label {
    font-size: 11px;
  }
}
