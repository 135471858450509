@import "../../styles/variables";

.filter_container {
  position: sticky;
  top: 0;
  bottom: 0;
  overflow-y: auto;

  .filter_content {
    display: none;
    max-height: 100vh;

    .filter_item {

      &:last-child {
        padding-bottom: 30px;
      }
    }

    .active & {
      display: block;
    }
  }

  .filter_container__top {
    padding: 20px 0;
    border-top: $borderFilterItem;
    border-bottom: $borderFilterItem;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    position: relative;
    background-color: $color_white;

    .filter_arrow__item {
      display: flex;
      align-items: center;
    }

    .filter_arrow_icons {
      display: flex;
      align-items: center;
      width: 50px;
      cursor: pointer;
      justify-content: center;
    }
  }

  .filter__icon {
    font-size: 25px;
  }
}