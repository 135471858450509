.upload_artwork_info {
  padding-top: 27px;

  &__image {
    margin-top: 20px;
    img{
      max-width: 100%;
      max-height: 100%;
    }
  }
}
