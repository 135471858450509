@import "../../styles/variables";

.comment {
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid #e6e6e6;

  .comment_grid {
    display: flex;
    grid-column-gap: 10px;
  }

  .comment_content {
    display: flex;
    flex-direction: column;
    grid-row-gap: 3px;
  }

  .comment_grid__avatar {
    cursor: pointer;
  }

  .comment_author {
    font-weight: 600;
    cursor: pointer;
  }

  .comment_date {
    font-size: 12px;
    display: flex;
    align-items: center;
    grid-column-gap: 5px;
  }

  .comment_text {
    font-size: 14px;

    .reply {
      text-decoration: underline;
    }
  }

  .comment_reply {
    display: flex;
    flex-direction: column;
    grid-row-gap: 15px;
    margin-top: 10px;
    margin-left: 75px;
    max-height: 300px;
    overflow-x: scroll;
  }

  .btn_view__reply {
    margin-left: 50px;
    padding: 3px 8px;
    border-radius: 8px;
  }
}

.comment_input {
  display: flex;
  align-items: center;
  grid-column-gap: 10px;

  input {
    padding: 5px 10px;
    border: 1px solid $color_light_grey;
    height: 30px;
    width: 100%;
    border-radius: 4px;
    margin-right: 10px;
  }

  .input_grid {
    display: flex;
    align-items: center;
    width: 700px;
    grid-column-gap: 10px;
  }

  .text_area {
    flex-grow: 1;
    box-sizing: border-box;
    border: 1px solid $color_light_grey;
    padding: 5px 10px;
  }
}
