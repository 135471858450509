.search {
  position: relative;
  flex-grow: 1;

  &_input {
    width: 100%;
    border: 1px solid #222222;
    padding: 7px 14px 7px 32px;
    height: 40px;
    box-sizing: border-box;
  }

  &_icon {
    position: absolute;
    width: 20px;
    left: 8px;
    top: 48%;
    transform: translateY(-50%);
  }
}
