.lost-page-grid {
  display: flex;
  flex-direction: column;
  grid-row-gap: 25px;
  overflow: hidden;

  .art-show {
    position: relative;
  }

  .tooltip_title {
    font-size: 16px;
  }

  .tooltip_text {
    font-size: 12px;
  }

  .lost_art_position {
    position: relative;
    display: inline-block;

    .MuiTooltip-tooltip {
      line-height: 18px;
      max-width: 500px;
      min-width: 400px;
    }
  }

  .view-more-btn {
    position: absolute;
    right: 0;
    top: 3px;
  }

  .question_mark_item {
    position: absolute;
    top: -2px;
    margin-left: 10px;
    cursor: pointer;
    right: -20px;
    font-size: 18px;
  }
}
