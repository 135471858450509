@import "../../styles/variables.scss";

.transfer_history {

  &__info {
    display: flex;
    flex-direction: column;
    grid-column-gap: 5px;
  }

  &__image {
    max-width: 200px;

    img {
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__owner {
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
  }
  
  &__date {
    padding: 10px 15px;
    font-size: 14px;
  }
  
  &__info_title {
    width: 150px;
    font-size: 14px;
    color: black;
  }

  &__text {
    display: flex;
    padding: 15px;
    border-top: 1px solid $color_light_grey;
    font-size: 14px;
    color: $color_grey;
  }
}
