@import "../../styles/variables";

.app_header {
  border-bottom: 2px solid #E0E0E1;
  padding: 5px 0;
  position: relative;

  .mobile_nav {
    position: absolute;
    width: 80%;
    height: 93vh;
    z-index: 9999;
    padding: 20px;
    box-sizing: border-box;
    transition: right 350ms;
    right: -90%;
    top: 66px;
    background-color: $color_white;
    &.active {
      right: 0;
      -webkit-box-shadow: -5px 0px 5px 0px rgba(0,0,0,0.2);
      box-shadow: -5px 0px 5px 0px rgba(0,0,0,0.2);
    }

    .nav {
      flex-direction: column;

      .nav_item.active {
        display: inline-block;
      }
    }
  }

  .app_header__grid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 50px;

    .logo {
      display: flex;
      align-items: center;

      .logo_image {
        margin-right: 10px;

        .logo_img {
          width: 50px;
        }
      }

      .logo_text {
        width: 115px;
        font-weight: 600;
        color: var(--color_black);
        font-size: 12px;
        margin-top: 3px;
      }
    }

    .app_header__user {
      display: flex;
      grid-column-gap: 10px;
      flex-grow: 1;

      .app_header__user__top {
        display: flex;
        grid-column-gap: 20px;
        justify-content: flex-end;
        margin-bottom: 15px;

        .app_header__notice {
          position: relative;

          img {
            width: 30px;
          }

          p {
            font-size: .5em;
            line-height: 2px;
            font-weight: 700;
            text-transform: uppercase;
          }

          .app_header__notice__counter {
            position: absolute;
            width: 15px;
            height: 15px;
            background-color: #D81F3F;
            border-radius: 4px;
            border: 1px solid $color_white;
            font-size: 12px;
            font-weight: 600;
            display: flex;
            align-items: end;
            justify-content: center;
            bottom: 8px;
            right: 0;
          }
        }

        .app_header__login {
          position: relative;
          padding-left: 30px;

          img {
            position: absolute;
            left: 0;
            width: 23px;
            margin-right: 5px;
          }

          a {
            top: 20%;
            font-size: .9em;
            font-weight: 600;
            display: flex;
            height: 99%;
            align-items: center;
          }
        }
      }
    }

    .download_moca .download_moca__text {
      text-align: right;
    }
  }
  .app_header__add_artwork_button {
    height: 40px;
    padding: 0 23px;
    font-size: 14px;
  }
}

@media (max-width: $media_sm) {
  .app_header {
    position: fixed;
    width: 100%;
    background-color: $color_white;
    z-index: 9999;

    .app_header__grid {

      .toggle_button {
        .toggle_button__icon {
          font-size: 40px;
        }
      }

      .download_moca {
        margin-top: 15px;
      }
    }
  }
}

