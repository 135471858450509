@import "../../styles/variables.scss";

.filter_materials_colors {
  overflow-y: scroll;

  .filter_materials_colors__input_wpapper {
    width: 95%;
  }

  .filter_materials_colors__input {
    cursor: text;
    display: flex;
    align-items: center;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    border: 1px solid rgb(229, 232, 235);
    padding: 6px 2px;
    margin-top: 10px;
    position: relative;
  }

  .filter_materials_colors__input_base {
    width: 100%;
    padding-left: 5px;
  }

  .filter_materials_colors__clear {
    cursor: pointer;
  }

  .filter_materials_colors__button {
    text-transform: none;
    padding: 5px;
    margin: 3px;
    border-radius: 5px;
    border: 1px solid rgba(25, 118, 210, 0.5);
    max-width: 85%;
    line-height: 15px;

    &.filter_materials_colors__more_button {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .filter_materials_colors__moreItems {
    border-left: 1px solid rgb(229, 232, 235);
    border-right: 1px solid rgb(229, 232, 235);
    border-bottom: 1px solid rgb(229, 232, 235);
    overflow: scroll;
    background-color: $color_white;
    position: absolute;
    width: 87%;
    z-index: 9999;
    display: flex;
    flex-wrap: wrap;
  }
}
