@import "../styles/variables";

.map_button {
  flex-grow: 1;
  height: 50px;
  font-size: 18px;
}



.description_picture__button {
  background-color: #000000;
  font-weight: 600;
  color: $color_white;
  width: 100%;
  height: 50px;
  font-size: 18px;
}

.shows-btn {
  height: 44px;
  border: 2px solid #88b04b;
  font-size: 20px;
  background-color: transparent;
  flex-grow: 1;
}

.invite {
  background-color: var(--color_black);
  color: $color_white;
  height: 40px;
  width: 250px;
  text-transform: uppercase;
  font-weight: 600;
}
