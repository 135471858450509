.input_field {
  .input_field__label {
    font-weight: 500;
  }

  .MuiInput-root::after {
    border-bottom: 2px solid #9d9ea1;
  }

  .css-1480iag-MuiInputBase-root-MuiInput-root.Mui-error:after {
    border-bottom: 1px solid #9d9ea1;
  }

  .MuiInput-root:hover:not(.Mui-disabled)::before {
    border-bottom: 2px solid #9d9ea1;
  }

  .Mui-error:after {
    border-bottom-color: #9d9ea1;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
  }

  .text_area {
    width: 100%;
    &:hover::before {
      border-bottom: 2px solid #9d9ea1;
    }
    &:focus::before {
      border-bottom: 2px solid #1976d2;
    }
    &::before {
      content: "";
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
      left: 0;
      bottom: 0;
      position: absolute;
      right: 0;
      -webkit-transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      pointer-events: none;
    }

    .text_area__input {
      width: 100%;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.4375em;
      letter-spacing: 0.00938em;
      color: rgba(0, 0, 0, 0.87);
      box-sizing: border-box;
      position: relative;
      cursor: text;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      border: none;
      resize: none;
    }
  }
}
