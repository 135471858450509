@import "../../styles/variables.scss";

.artist_profile_tabs {
  .artist_profile_tabs__tabs_container {
    margin-top: 32px;
    display: flex;
    align-items: center;

    .MuiTabs-indicator {
      background-color: $title_color;
      height: 2px;
    }

    .MuiTabs-flexContainer {
      display: flex;
      width: 100%;
    }

    .artist_profile_tabs__tab_item {
      text-transform: none;
      max-width: 100%;
      font-weight: 500;
      font-size: 15px;
      border-bottom: 1px solid transparent;
      color: $title_color;
      padding: 3px;
      min-height: 0;
      min-width: 0;
      margin-right: 40px;

      &:hover {
        color: $color_black;
      }
    }
  }
}
