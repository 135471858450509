@import "../../styles/variables";

.filter_results {
  display: flex;
  grid-gap: 15px;
  flex-wrap: wrap;
  align-items: center;

  .clear {
    color: $color_black;
  }
}
