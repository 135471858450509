  .filter_categories {
    display: flex;
    flex-direction: column;

    .filter_categories__input {
      cursor: text;
      display: flex;
      align-items: center;
      background-color: rgb(255, 255, 255);
      border-radius: 10px;
      border: 1px solid rgb(229, 232, 235);
      padding: 6px 2px;
    }
    
    .filter_categories__searchIcon {
      color: rgb(112, 122, 131);
      margin-right: 8px;
    }

    .filter_categories__items {
      display: flex;
      flex-direction: column;
      padding: 10px;
      height: 300px;
      overflow-y: scroll;
    }
  }

