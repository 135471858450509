@import "../../App";

.signup {
  .MuiTabs-flexContainer {
    justify-content: space-between;
  }

  .registration_tab__item {
    border: 2px solid $color_black;
    text-transform: capitalize;
    border-radius: 4px;
    height: 40px;
    box-sizing: border-box;
    padding: 0 25px;
    font-size: 18px;
    color: $color_black;
    width: 45%;
  }

  .Mui-selected.registration_tab__item {
    background-color: $color_black;
    color: $color_white;
    border: 2px solid transparent;
  }

  .MuiTabs-indicator {
    display: none;
  }

  .MuiTabPanel-root {
    padding: 0;
    margin-top: 10px;
  }

  .MuiInputLabel-root {
    text-transform: uppercase;
  }

  .registration_check__block {
    display: flex;
    margin-top: 20px;

    .registration_check {
      padding: 0;
      align-items: flex-start;
    }

    .registration_check__text {
      margin-left: 10px;
      color: $color_grey;
      text-align: center;
    }

    .registration_check__link {
      text-decoration: underline;
    }
  }

  .registration_buttons {
    margin-top: 20px;

    .registration_buttons__or {
      position: relative;
      margin: 20px 0;
      text-align: center;
      text-transform: uppercase;

      &::after {
        content: "";
        width: 100%;
        height: 2px;
        background-color: $color_grey;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -1px;
        z-index: -1;
      }

      .registration_buttons__or__text {
        display: inline-block;
        width: 35px;
        background-color: $color_white;
        color: $color_grey;
      }
    }

    .quick_start__button {
      width: 100%;
      color: $color_grey;
      border-color: $color_grey;
    }
  }
}
