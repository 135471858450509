.search_tabs {
  .search_tab {
    color: #222222;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;

    &.Mui-selected {
      color: #000000;
    }
  }

  & .MuiTabs-indicator {
    background-color: #000000;
  }
}

.search_tabs__body {
  & .MuiTabPanel-root {
    padding: 0;
  }
}