.search-results {
    margin-top: 20px;
    margin-bottom: 15px;
}

.no-results {
    width: 100%;
    text-align: center;
    color: rgb(71, 71, 71);
    margin-bottom: 20px;
}