@import "../../styles/variables";

.show_info {
  padding-bottom: 75px;

  &__grid {
    padding-bottom: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 50px;

    &__titles {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: $title_h6__margin;
    }

    &__title {
      font-size: $title_h2;
      font-weight: 600;
      margin-bottom: $title_h2__margin;
    }
  }

  &__title_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .show_info__owner {
    display: inline-flex;
    grid-column-gap: 10px;
    margin-bottom: 20px;
    align-items: center;

    .show_info__owner__content {
      font-size: 14px;

      .show_info__owner__date {
        color: $color_grey;
      }
    }
  }

  .show_info__owner {
    display: inline-flex;
    grid-column-gap: 10px;
    margin-bottom: 20px;
    align-items: center;
    cursor: pointer;
  }

  .show_info__owner__content {
    font-size: 14px;
    display: flex;
    grid-column-gap: 15px;

    .show_info__owner__date {
      color: $color_grey;
    }

    .show_info__btn__send {
      position: absolute;
    }
  }

  .show_info__titles {
    margin-bottom: 5px;
  }

  .calendar_grid {
    display: inline-flex;
    background-color: #f8f8f8;
    padding: 5px;
    grid-column-gap: 10px;

    .calendar_duration {
      display: flex;
      align-items: center;
      grid-column-gap: 10px;
    }
  }

  .show_info__location {
    color: $color_grey;
  }

  .show_info__about {
    margin-top: 50px;
  }

  .show_info__image__wrap {
    display: flex;
    flex-direction: column;
    grid-row-gap: 10px;

    .show_info__image {
      width: 100%;
    }
  }

  .show_info__submit {
    margin-top: 10px;
  }

  &__artworks_grid {
    margin-top: 30px;
  }

  &__tabs {
    margin-top: 50px;
    display: flex;
    align-items: center;

    .MuiTabs-indicator {
      background-color: $color_black;
      height: 1px;
    }

    .MuiTabs-flexContainer {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    .show_info__tab_item {
      text-transform: none;
      width: 50%;
      font-size: $title_h4;

      &.Mui-selected {
        color: $color_black;
      }
      &:hover {
        color: $color_black;
      }
    }
  }

  &__report_share {
    display: flex;
    align-items: center;
  }
}
