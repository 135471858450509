.moca_tabs {
  display: flex;
  column-gap: 24px;
  margin-bottom: 15px;

  .tab_label {
    cursor: pointer;
    font-weight: 400;
  }

  .tab_label__selected {
    font-weight: 500;
  }
}