@import "../../styles/variables";

.registration {
  .registration_image__block {
    display: flex;
    justify-content: center;
    .registration_image {
      width: 80px;
      margin-bottom: 10px;
    }
  }

  .registration_title {
    font-weight: 600;
    font-size: 18px;
    text-align: center;
  }

  .registration_title__sub {
    text-align: center;
    margin-bottom: 20px;
  }

  .registration_input {
    margin-top: 20px;
    .MuiInput-root::before {
      border-bottom: 1px solid  $color_grey;
    }
    .MuiInput-root::after {
      border-bottom: 2px solid  $color_grey;
    }
    .MuiInput-root:focus::after {
      border-bottom: 2px solid  $color_grey;
    }

    .MuiInput-root.Mui-error::after {
      border-bottom: 2px solid  $color_error;
    }

    .MuiInput-root:hover:not(.Mui-disabled)::before {
      border-bottom: 2px solid  $color_grey;
    }

    .MuiInputLabel-root {
      &.Mui-error.Mui-focused {
        color: $color_error;
      }
      &.Mui-focused {
        color: $color_light_black;
      }
    }
  }

  .registration_input__icon {
    fill: $color_black;
  }

  .signup_have {
    text-align: center;
    font-weight: 600;
    margin-top: 30px;
    .signup_link {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .alert_error {
    margin-top: 10px;
  }

  .alert_error__message {
    font-size: $title_h6;
    font-weight: 600;
  }

  .forgot_pass {
    display: flex;
    justify-content: right;
    margin-top: 5px;

    .forgot_pass__link {
      font-size: 12px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .login_button {
    margin-top: 20px;
  }

  .under_input {
    font-weight: 400;
    font-size: 12px;
    color: #757575;
    margin-top: 5px;
  }
}