.about_page {
  display: grid;
  min-height: calc(100vh - 66px);
  grid-template-rows: 1fr;

  .about_page__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .app_store__button {
    width: 130px;
  }

  .google_play__button {
    width: 145px;
  }
}
