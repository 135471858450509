.venues {
  .category {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    grid-column-gap: 25px;

    .category_item {
      min-width: 300px;
    }
  }
  .filter_top_bar {
    justify-content: flex-start;
  }
  .select_sort {
    margin-right: 15px;
  }
}
