@import "../../styles/variables";

.privacy_policy_page {
  display: flex;
  flex-direction: column;
  align-items: center;

  .privacy_policy_page__title {
    margin-top: 10px;
  }

  .privacy_policy_page__text {
    white-space: pre-line;
    color: $color_black;
    padding-bottom: 30px;
  }
}

@media (max-width: $media_sm) {
  .privacy_policy_page {
    padding: 0 10px;
  }
}
