@import "../../styles/variables";

.edit_profile {
  padding-top: 25px;
  margin: 0 auto;
  width: 50%;

  .edit_container {
    //width: 50%;
  }

  .edit_container {
    display: flex;
    flex-direction: column;
  }

  .submit_form {
    align-self: center;
  }
  .origin_label {
    font-weight: 500;
  }
  .location_autocomplete {
    .edit_item__location:hover::before {
      background-color: #9d9ea1;
    }
  }
}

.edit {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;

  .edit_form {
    display: flex;
    flex-direction: column;
  }

  .edit_form__content {
    margin-top: 30px;
  }

  .edit_title__wrap {
    position: relative;
  }

  .edit_title {
    font-size: 30px;
  }

  .arrow__back {
    font-size: 40px;
    top: 20px;
    left: -50px;
  }

  .text_field {
    width: 100%;
  }

  .origin_label {
    text-transform: uppercase;
    color: #000000;
    font-size: 12px;
  }

  .register_item {
    margin-bottom: 20px;
  }

  .edit_item {
    position: relative;
  }

  .edit_button__modal {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 5px;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: $shadow_btn;
    border-radius: 4px;
    position: absolute;
    background: $color_white;
    z-index: 1;
  }

  .edit_choice__button {
    display: inline-block;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
    box-sizing: border-box;
  }

  .edit_delete {
    font-size: 12px;
    font-weight: 600;
    color: $color_black;
    cursor: pointer;
    padding: 6px;
  }

  .origin_item__input {
    display: flex;
    align-items: center;
    grid-column-gap: 10px;
    position: relative;
  }

  .register_item__select {
    width: 100%;

    fieldset {
      border: none;
      border-bottom: 1px solid $color_grey;
      border-radius: 0;
    }

    .MuiSelect-select {
      padding: 4px 0 5px;
    }

    .MuiSelect-icon {
      right: 0;
    }
  }

  .register_item {
    position: relative;
  }

  .choose_category__span {
    position: absolute;
    font-size: 12px;
    left: 0;
    bottom: 5px;
    z-index: -1;
  }

  .edit_item__location {
    border-bottom: 1px solid $color_grey;
    box-sizing: border-box;
    position: relative;

    &:hover::before {
      content: "";
      position: absolute;
      bottom: -1px;
      display: block;
      width: 100%;
      height: 2px;
      background-color: $color_black;
      z-index: 1;
    }

    .css-yk16xz-control {
      border: none;
    }

    .css-1pahdxg-control {
      border: none;
      box-shadow: none;

      &:hover {
        border: none;
        box-shadow: none;
      }
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    .css-1hwfws3 {
      padding: 4px 0 5px;
    }

    .css-1wa3eu0-placeholder {
      font-size: 12px;
    }
  }

  .edit_select__units {
    width: 100%;
    border: none;
    border-bottom: 1px solid $color_grey;
  }

  .double_input {
    display: flex;
    grid-column-gap: 5px;
    align-items: center;
  }

  .double_input__content {
    width: 100%;
  }

  .double_input__value {
    display: grid;
    grid-template-columns: 60% 1fr;
    grid-column-gap: 30px;
    width: 100%;

    .second_input {
      //width: 50%;
    }
  }

  .double_input__label {
    display: flex;
    grid-column-gap: 10px;
    label {
      flex-basis: 50%;
    }
  }

  .double_input__items {
    display: flex;
    flex-grow: 1;
    width: 100%;
    align-items: flex-end;
  }

  .register_item__url {
    display: flex;
    align-items: center;
  }

  .copy {
    width: 100%;
  }

  .copy_url {
    text-transform: inherit;
    color: $color_black;
  }

  .under_input {
    font-weight: 400;
    font-size: 12px;
    color: #757575;
    margin-top: 5px;
  }

  .edit_text {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .submit_form {
    margin: 30px auto 0;
  }

  .register_select__wrap {
    position: relative;
  }
}

.categories_description {
  font-size: 14px;
  color: #999B9E;
}
