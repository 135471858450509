.notices {
  margin-top: 30px;
}

.transfer-notice {
  font-size: 11px;
  margin-bottom: 15px;
}

.transfer-type-checkboxes {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}

.origin_label {
  text-transform: uppercase;
  color: #000000;
  font-size: 12px;
  pointer-events: none;
}
