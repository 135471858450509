@import "../../styles/variables";

.upload_image {
  display: grid;
  grid-template-rows: 70px 1fr 70px;
  min-height: calc(100vh - 86px);
  margin-top: 20px;

  .upload_image__title {
    text-align: center;
    font-size: $title_h1;
  }

  .upload_image__link {
    font-weight: 600;
    text-decoration: underline;
  }

  .upload_profile__grid {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 0 auto;
    padding: 15px 0 50px;
    text-align: center;

    .next_page {
      align-self: flex-end;
    }

    .add_icon {
      font-size: 50px;
      fill: $color_grey;
    }

    .upload_profile__image {
      text-align: left;
    }

    .upload_image__photo {
      padding: 30px 0;
      border-bottom: 1px solid $color_grey;
      display: flex;
      justify-content: center;

      .add_icon__button {
        background-color: transparent;
        border: 1px solid $color_grey;
        box-shadow: none;
        transition: box-shadow 300ms;
        overflow: hidden;
        z-index: 1;

        img {
          height: 100%;
        }

        .upload_file {
          position: absolute;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
        }

        .add_file {
          position: absolute;
          width: 60%;
          opacity: 0;
        }

        &:hover {
          box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12);;
        }
      }
    }

    .upload_background__image__wrap {
      text-align: left;
      margin-bottom: 30px;
      .upload_input__file {
        z-index: 1;
      }
    }

    .upload_profile__text {
      margin: 15px 0;
    }

    .upload_background__image {
      border: 1px solid $color_grey;
      border-radius: 10px;
      height: 100px;
      cursor: pointer;
      transition: box-shadow 300ms;
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
        object-fit: cover;
      }

      .add_icon {
        position: absolute;
        transform: translateX(-50%) translateY(-50%);
        left: 50%;
        top: 50%;
      }

      &:hover {
        box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12);;
      }
    }

    .crop_background__image {
      height: auto;
    }
  }

  .upload_image__footer {
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 60px;
    align-items: center;
    border-top: 3px solid #E0E0E1;
    height: 70px;
    padding: 0 58px;
  }
}
