@import "../../styles/variables";

.notifications {
  .notification_icon {
    font-size: 30px;
    color: $color_black;
  }
}

.MuiMenu-list {
  .notice_list__item {
    cursor: auto;
  }
}

.notification_item {
  display: flex;
  grid-column-gap: 10px;
  font-size: 14px;
  line-height: 18px;
}

.follow_notification {
  &__time {
    font-size: 12px;
    color: $color_grey;
  }
}

.notification_link {
  font-weight: 600;
  text-decoration: none;
}

.notification_text {
  color: $color_black;
  width: 350px;
  white-space: pre-wrap;
}

.notification_time {
  font-size: 12px;
  color: $color_grey;
}

.notification_like__post__body {
  border: 1px solid $color_light_grey;
  border-radius: 5px;
  padding: 5px 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;

  .notification_text {
    font-size: 12px;
  }
}

.notification_icons__item {
  width: 20px;
  height: 20px;

  .notification_icon {
    font-size: 20px;
  }

  img {
    width: 100%;
  }
}

.notification_avatar {
  display: block;
  max-height: 35px;
}

.notification_icons {
  display: flex;
  grid-column-gap: 20px;
  margin-top: 10px;

  .notification_icons__item {
    transition: box-shadow 350ms;
    border-radius: 50%;
    padding: 5px;
    position: relative;
  }
}

.notification_reply {
  display: inline-block;
  color: $color_grey;
  font-weight: 500;
  margin-top: 10px;
  cursor: pointer;
}

.notification_image {
  margin-top: 10px;
  img {
    max-width: 250px;
    width: 100%;
  }
}

#account-menu {
  .MuiPaper-root {
    overflow: scroll;
    max-height: 600px;
  }
}

.notification_border {
  border: 1px solid $color_light_grey;
  border-radius: 5px;
  padding: 10px;
  display: inline-block;
}
