@import "../../styles/variables.scss";

.shows_page {
  .category {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 725px;
    margin: 25px auto 0;
  }

  .category_item {
    min-width: 300px;
  }

  .category-grid {
    display: flex;
    grid-column-gap: 25px;
    margin: 25px 0;
  }

  .btn-link-shows {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 18px;
    height: 44px;
    background-color: #dddddd;
    width: 100%;
  }

  .shows-button {
    display: flex;
    grid-column-gap: 25px;
    width: 100%;
  }

  .institution_card {
    width: 100%;
  }

  .institution_card__image {
    height: 357px;
  }

  &__panel {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 10px;
    margin-bottom: 40px;
  }

  &__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 28px;
  }
  .select_sort {
    padding-right: 0;
  }
  .btn {
    height: 50px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    &.shows_page__submit_button {
      color: $title_color;
      border: 2px solid #222222;
      &:hover {
        color: $color_white;
      }
    }
    &.shows_page__post_button {
      grid-column: 2;
    }
  }

  &__masonry_grid {
    display: flex;
  }

  &__masonry_grid_panel {
    display: flex;
    margin-bottom: 40px;
    margin-top: 15px;
  }

  &__grid_column {
    box-sizing: border-box;
    padding-right: 10px;

    &:last-child {
      padding-right: 0;
    }
  }
  &__location_autocomplete {
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
    .css-yk16xz-control {
      background-color: $select_background_color;
      color: $select_text_color;
      border: none;
      height: 50px;
      cursor: pointer;
    }
    .css-1pahdxg-control {
      background-color: $select_background_color;
      color: $select_text_color;
      border: none;
      box-shadow: none;
      height: 50px;
      cursor: pointer;
    }
    .css-26l3qy-menu {
      div {
        cursor: pointer;
      }
    }
    .css-1pahdxg-control:hover {
      border: none;
    }
  }

  &__location_autocomplete_container {
    height: 300px;
    padding: 20px;
  }

  &__dialog_cancel {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}

@media screen and (max-width: $media_lg) {
  .shows_page {
    &__buttons {
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: none;
      grid-row-gap: 10px;
    }
  }
}
