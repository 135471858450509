@import "../../styles/variables.scss";

.artists_page {
  &__masonry_grid {
    display: flex;
  }

  &__grid_column {
    box-sizing: border-box;
    padding-right: 10px;

    &:last-child {
      padding-right: 0;
    }
  }

  &__filter_top_panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 14px;
  }

  &__results {
    margin-right: 15px;
  }

  &__filter_left_block {
    display: flex;
    align-items: center;
    grid-row-gap: 15px;
    flex-wrap: wrap;
  }

  .sort_name {
    margin-right: 20px;
  }

  &__radius_slider {
    margin-top: 20px;
  }
}
