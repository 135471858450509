@import "../../styles/variables";

#button_result {
  border-color: $color_black;
  color: $color_black;
  text-transform: capitalize;

  &:hover {
    border-color: $color_light_black;
  }

  .button_result__title {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .close {
    font-size: 18px;
    margin-left: 10px;
  }
}
