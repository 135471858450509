@import "../../styles/variables";

.filter_item {
  &__title {
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
    padding: 14px 0;
    padding-right: 16px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    background-color: $color_white;
  }

  &__unit {
    color: #e7e7e7;
  }

  .filter_item__content {
    padding-right: 16px;
    padding-left: 5px;
  }
}
