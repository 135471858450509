@import "../../styles/variables";

.register_institution {
  padding-top: 50px;

  .register_title__wrap {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding-left: 50px;
    position: relative;

    .register_title {
      font-size: $title_h2;
      color: $color_black;
    }

    .arrow__back {
      font-size: 40px;
    }
  }

  .edit_title__wrap {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .arrow__back_icon {
      cursor: pointer;
      font-size: 40px;
      margin-left: -50px;
      margin-right: 10px;
      position: relative;
    }
  }
  .edit_button {
    display: flex;
    justify-content: center;
    .submit_form {
      font-size: 16px;
      
    }
  }

  .register_institution__form {
    .register_radio {
      border-top: 1px solid $color_grey;
      border-bottom: 1px solid $color_grey;
      padding: 20px 0;
    }

    .login_button {
      width: 250px;
      margin: 0 auto;
      display: block;
    }

    .register_item {
      margin-bottom: 42px;

      .css-fdrafe {
        text-transform: inherit;
      }

      label {
        color: $color_black;
        z-index: 0;
      }

      .origin_select {
        width: 100%;
      }

      .description__textarea {
        padding: 4px 0 5px;
        border-bottom: 1px solid #9d9ea1;
        position: relative;

        &:hover::before {
          content: "";
          position: absolute;
          bottom: -1px;
          display: block;
          width: 100%;
          height: 2px;
          background-color: $color_black;
          z-index: 1;
        }
      }
    }
  }

  .upload_image__footer {
    display: flex;
    justify-content: center;
    grid-column-gap: 60px;
    align-items: center;
    border-top: 3px solid #e0e0e1;
    height: 70px;
    padding: 0 58px;
  }

  .upload_image__link {
    font-weight: 600;
    text-decoration: underline;
  }

  .register_institution__wrapper {
    margin: 0 auto;
    width: 50%;
  }
  .loader {
    position: fixed;
  }
}
