@import "../../styles/variables";

@media (max-width: $media_sm) {
  .explore {
      .explore_list {
        grid-template-columns: 1fr;
        margin-top: 0;
      }
  }
  .artworks_item__side-bar {
    display: none;
  }
}