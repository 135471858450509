@import "../../styles/variables";

.tabs {
  padding: 5px 10px;
  border-radius: 4px;
  display: inline-block;
  cursor: pointer;
}

.artworks-tabs-item {
  font-weight: 600;
  margin-right: 15px;
  margin-top: 15px;
  transition: background-color 0.25s, background-color 0.25s;

  &:hover {
    background-color: #000000;
    color: $color_white;
  }
}

.artworks-tabs-item.active {
  background-color: #000000;
  color: $color_white;
}

.map-page-tab {
  font-weight: 600;
  font-size: 18px;
  box-sizing: border-box;
  border: 1px solid transparent;
}

.map-page-tab.active {
  border: 1px solid #000000;
  border-radius: 5px;
}
