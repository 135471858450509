@import "../../styles/variables";

.login {
  width: 350px;
}

@media screen and (max-width: $media_sm) {
  .ReactModal__Content {
    width: 80%;
    .login {
      width: auto;
    }
  }
}