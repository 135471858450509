.edit_modal {
    margin: 0 35px 15px 35px;
    display: flex;
    flex-direction: column;
  
    &_content {
      margin-top: 20px;
    }
  
    &_title__wrap {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      margin-left: -29px;
      gap: 5px;

      .edit_modal_back_button {
        cursor: pointer;
      }

      .edit_modal_sub_title {
        font-size: 15px;
      }
    }
  
    &_buttons {
      display: flex;
      justify-content: center;
      margin-top: 25px;

      .accept_button {
        padding: 5px 20px;
      }
    }
  
    &_images {
      position: relative;
      margin-bottom: 15px;
  
      .slider_arrow_button {
        height: 100%;
        color: #000000;
      }
  
      .prev {
        left: -16px;
      }
  
      .next {
        right: -23px;
      }
  
      &_image {
        max-height: 200px;
        object-fit: cover;
      }
    }
  
    .origin_label {
      text-transform: uppercase;
      color: #000000;
      font-size: 12px;
      pointer-events: none;
    }
  
    .register_item {
      margin-bottom: 10px;
    }
  
    .css-1480iag-MuiInputBase-root-MuiInput-root:before {
      border-bottom: 1px solid rgb(198, 198, 198) !important;
    }
  
    .MuiInput-root:hover:not(.Mui-disabled)::before {
      border-bottom: 1px solid rgb(198, 198, 198) !important;
    }
    
    .MuiInput-root::after {
      border-bottom: none;
    }
  }
  
  .leaflet-top,
  .leaflet-bottom {
    z-index: 800 !important;
  }
  
  .MuiModal-root {
    z-index: 900 !important;
  }
  
  .MuiDialogTitle-root {
    padding-top: 8px !important;
  }
  