@import "../../styles/variables";

.crop_image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .crop_image__button {
    font-size: 14px;
    font-weight: 600;
  }

  .crop_image__choose {
    color: #f6ce45;
  }

  .crop_image__cancel{
    color: #2f69d0;
  }

  .crop_image__around {
    display: flex;
    color: $color_white;
  }

  .crop_image__buttons {
    display: flex;
    grid-column-gap: 15px;
    align-items: center;
    margin-top: 10px;
  }
}