@import "../../styles/variables.scss";

.profile-scans-page {
  &__map {
    height: 60vh;
  }
  .leaflet_map {
    height: 60vh;
  }
  .share-button {
    margin-left: 30px;
  }
  .profile-scans-page__buttons {
    display: flex;
    align-items: center;
  }
  .profile-scans-page__button {
    height: 40px;
    background-color: #0001;
    min-width: 146px;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    margin-right: 15px;
    margin-bottom: 15px;
    color: $title_color;

    &:hover {
      color: $color_white;
    }
  }
}
