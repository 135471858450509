.explore-item-details-title {
  font-size: 38px;
  text-transform: uppercase;
}

.description-picture-title {
  margin-bottom: 10px;
  font-size: 20px;
}

.show-item-title {
  font-weight: 500;
  font-size: 18px;
}

.artist-profile h2 {
  font-weight: normal;
  font-size: 36px;
}

.title-image {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
