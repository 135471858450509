@import "../../styles/variables";

.modal_wrap {
  position: relative;
  min-width: 250px;
  max-width: 350px;

  .close-svg {
    width: 15px;
    position: absolute;
    left: -10px;
    top: -10px;
    cursor: pointer;
    path {
      fill: $color_black;
    }
    &:hover {
      path {
        fill: $color_light_black;
      }
    }
  }
}