@import "../../styles/variables";

.filter_price {

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__input {
    height: 48px;
    box-sizing: border-box;
    padding: 0 12px;
    width: calc(50% - 15px);
    border-radius: 10px;
    border: 1px solid rgb(229, 232, 235);
    background-color: $color_white;
  }

  &__button {
    margin-top: 10px;
    font-weight: 600;
    cursor: pointer;
    &:hover {
      transition: all 0.2s ease 0s;
      box-shadow: rgba(4, 17, 29, .25) 0 0 8px 0;
    }
    &:disabled {
      box-shadow: none;
    }
  }

  .filter_error {
    margin-top: 10px;
    font-weight: 500;
    font-size: 12px;
    color: rgb(235, 87, 87);
  }
}
