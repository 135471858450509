@import "../../styles/variables";

.main {
  flex-grow: 1;
  min-height: calc(100vh - 66px);
}

@media (max-width: $media_sm) {
  .main {
    padding-top: 75px;
  }
}
