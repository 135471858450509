.image_list {
  display: flex;
  grid-column-gap: 12px;
  overflow: hidden;

  .image_list__item {
    cursor: pointer;
  }

  img {
    height: 150px;
  }
}
