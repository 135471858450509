@import "../../styles/variables.scss";

.explore_item_details_right {
  &__artist_name {
    margin-top: 5px;
  }

  &__description {
    margin-top: 15px;
    display: grid;
    grid-row-gap: 25px;
    margin-bottom: 15px;
  }

  &__title {
    font-size: 20px;
  }

  &__title_wrapper {
    display: flex;
  }

  &__report_button {
    margin-left: auto;
  }

  &__owner {
    align-items: center;
    width: 100%;
  }

  &__icon {
    width: 40px;
    height: 40px;

    img {
      width: 100%;
    }
  }

  &__winner,
  &__tv {
    display: flex;
    align-items: center;
    grid-column-gap: 15px;
  }

  &__tv_text {
    font-size: 16px;
  }

  &__winer_text {
    font-size: 16px;
  }

  &__tv_text {
    display: flex;
    flex-direction: column;

    .explore_item_details_right__request_use_button {
      padding: 2px;
      font-size: 12px;
      width: fit-content;
      line-height: normal;
    }
  }

  &__materials,
  &__dimensions {
    margin-bottom: 10px;
    margin-top: 15px;
    font-size: 15px;
    line-height: 20px;
  }

  &__uploaded {
    font-size: 15px;
    line-height: 20px;
  }

  &__price {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 40px;
    font-weight: bold;
  }

  &__pending {
    margin-bottom: 25px;
    h4 {
      margin-bottom: 10px;
    }
  }

  &__insperation {
    margin-bottom: 25px;
  }

  &__past_exhibitions {
    margin-bottom: 25px;
  }

  &__more_info_button {
    width: fit-content;
    text-decoration: underline;
    padding: 0;
  }

  &__buy_buttons {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 15px;

    .btn {
      width: 45%;
      font-size: 15px;

      &:first-child {
        margin-right: 15px;
      }
    }
  }

  &__action_buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;
    margin-bottom: 25px;
    margin-top: 20px;

    button {
      font-size: 15px;
      margin-right: 15px;
      width: 45%;
      min-height: 39px;
    }
  }

  .show_transfer {
    button {
      font-size: 15px;
      margin-right: 10px;
      width: 30%;
      min-height: 39px;
    }
  }

  &__collector_buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;
    margin-bottom: 25px;

    button {
      font-size: 15px;
      margin-right: 10px;
      width: 45%;
      min-height: 39px;
    }
  }

  .show_artwork__identify {
    font-size: 14px;
    color: $color_grey;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 20px;
  }

  .show_artwork__identify__suggestion {
    color: $color_black;
    text-decoration: none;
    line-height: 20px;
  }

  &__dialog_container {
    min-height: 60px;
    display: flex;
    align-items: end;
    justify-content: space-around;
    padding: 20px 0;
  }

  &__dialog_button {
    width: 200px;
  }
}

.explore_item_details_right__footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 25px;
  box-sizing: border-box;
  border-top: 1px solid #D9D9D9;

  .type_comment {
    margin-bottom: 10px;
    font-size: 13px;

    .comment_type_btn {
      margin-left: 4px;
      padding: 0;
      font-size: 14px;

      &:first-child {
        margin-right: 4px;
      }
    }

    .active {
      font-weight: 500;
    }
  }

  label {
    width: 100%;
    position: relative;
  }

  .comment_input {
    padding: 11px 50px 11px 16px;
    width: 100%;
    box-sizing: border-box;
    border: none;
    background: rgba(238, 233, 233, 0.5);
    resize: none;
  }

  .comment_input_btn {
    font-weight: 400;
    font-size: 14px;
    position: absolute;
    z-index: 1;
    right: 10px;
    border: none;
    background: none;
    text-transform: uppercase;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .btn {
    font-weight: 400;
  }
}
