.artworks_details_map {
  
  .leaflet_map{
    margin-bottom: 15px;
  }

  &__tabs {
    display: flex;
    margin-bottom: 15px;
    margin-top: 20px;
  }

  &__tabs_item {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: black;
    font-weight: 600;
    transition: all .35s;
    border: 1px solid #ffff;
    border-radius: 4px;
    line-height: 1.5;
    padding: 0 10px;
    margin-right: 20px;

    &.active, &:hover {
      background-color: black;
      color: white
    }
  }
}
