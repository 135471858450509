.input_multi {
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  &__item {
    display: flex;
    align-items: center;
    grid-column-gap: 10px;
  }
}

.close_delete {
  cursor: pointer;
}

.add_input__multi {
  font-size: 30px;
  cursor: pointer;
}

.add_input__multi__delete {
  text-transform: lowercase;
}