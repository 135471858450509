@import "../../styles/variables";

.artwork_identify {
  border: 1px solid $color_light_grey;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;

  .artwork_identify__text {
    text-align: center;
    font-size: 18px;
  }

  .artwork_identify__title {
    text-align: center;
    font-size: 25px;
    margin-top: 5px;
    margin-bottom: 20px;
  }

  .artwork_identify__btn {
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .dont_identify {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .dont_identify__text {
    font-size: 14px;
    color: $color_grey;
  }

  .artwork_identify__text__btn {
    font-weight: 600;
    cursor: pointer;
  }
}

.open_artist {
  background-color: rgba(0, 0, 0, .9);
}

.open_artist__title {
  text-align: center;
  font-size: 20px;
  margin-bottom: 15px;
}

.open_artist__image {
  max-height: 300px;
  overflow: hidden;

  img {
    object-fit: cover;
    width: 100%;
  }
}

.open_artist__form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  padding: 20px;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;

  .close-svg {
    width: 15px;
    position: absolute;
    left: 10px;
    top: 10px;
    cursor: pointer;
    & path {
      fill: $color_black;
    }
  }

  .label {
    font-size: 12px;
    color: $color_black;
  }

  .form_item {
    position: relative;
  }

  .open_artist__check {
    position: absolute;
    right: 0;
    margin-right: 0;
    bottom: 2px;
    background: #ffffff;
    z-index: 1;
    height: 30px;
  }

  .MuiCheckbox-root {
    width: 35px;
    height: 35px;
  }

  .MuiCheckbox-root:hover {
    background-color: transparent;
  }

  .open_form {
    display: flex;
    flex-direction: column;
    grid-row-gap: 10px;
  }
}