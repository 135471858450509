@import "../../styles/variables";
.artworks_explore {
  color: $color_black;

  .explore_list {

    .artworks_list {
      gap: 12px;
    }
  }
}