@import "../../styles/variables";

.light_box__images {
  display: grid;
  grid-gap: 10px;

  .arrow_slider {
    fill: #fff;
  }

  .slider_arrow_button:disabled {
    .arrow_slider {
      opacity: .5;
    }
  }
}

.light_box__buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .btn {
    color: $color_white;
    font-weight: 400;
  }
}

.main_image {
  background-color: #222;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;

  img {
    object-fit: contain;
    height: 100%;
    max-width: 100%;
  }
}

.light_box__image {
  overflow: hidden;
  cursor: pointer;
  transition: box-shadow .35s;
  max-height: 150px;

  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
}