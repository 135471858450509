.user-followers {
  .MuiPaper-root {
    max-width: 400px;
  }
  .MuiDialogTitle-root {
    margin-left: 20px;
  }
  &__user-wrapper {
    padding: 0 20px;
    height: 70vh;
    min-height: 70vh;
    overflow-y: scroll;
  }
  &__back-button {
    position: absolute;
    top: 20px;
    left: 10px;
  }
  .artist_item__description {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .likes {
      display: none;
    }
  }
}
