@import "../../styles/variables";

.nav {
  display: flex;
  grid-column-gap: 10px;

  .nav_item {
    display: inline-block;
    padding: 5px 10px;
    font-weight: 600;
    font-size: 1.15em;
    box-sizing: border-box;
    border: 2px solid transparent;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .nav_item.active {
    border-color: var(--color_black);
    border-radius: 5px;
  }
}

.nav_menu__explore {
  display: flex;
  align-items: center;
  grid-column-gap: 30px;
  width: 100%;
  height: 56px;

  .nav_item {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }

  .active {
    background-color: $color_black;
    color: $color_white;
    padding: 6px 14px;
    border-radius: 5px;
    
    &.blocked {
      pointer-events: none;
    }
  }
}

.nav_menu__login {
  display: flex;
  align-items: center;
  grid-column-gap: 5px;

  a {
    height: 30px;
    padding: 5px;
    display: block;
    border-radius: 50%;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover {
      background-color: rgba(0, 0, 0, .04);
    }
  }

  .nav_menu__login__item {
    font-size: 30px;
  }
}
