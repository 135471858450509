@import "../../styles/variables.scss";

.map-page {
  &__map {
    height: 60vh;
    width: 100%;
  }
  .map {
    width: 100%;
    flex: content;
    display: flex;
    flex-direction: column;

    .filter_results__content {
      display: block;
    }

    .filter_results {
      margin-bottom: 20px;
    }
  }
  .leaflet_map {
    height: 60vh;
  }
  .share-button {
    margin-left: 30px;
  }
  .map-page__buttons {
    display: flex;
    align-items: center;
  }
  .map-page__button {
    height: 40px;
    background-color: #0001;
    min-width: 146px;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    margin-right: 15px;
    margin-bottom: 15px;
    color: $title_color;

    &:hover {
      color: $color_white;
    }
  }
  .map-container {
    width: 100%;
    display: flex;
    flex: content;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 50px;
  }
  .search-area__button {
    position: absolute;
    z-index: 2000;
    left: 50%;
    margin-top: 30px;
    transform: translateX(-50%);
    border-radius: 15px;
    color: $green_text_color;
    background: $color_white;
    border: none;
    box-shadow: $box_shadow;
    font-weight: 500;
    padding: 5px 7px;
    cursor: pointer;
    transition: 0.2s linear;

    &:hover {
      background: rgb(241, 241, 241);
    }
  }
}
