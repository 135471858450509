$color_black: #000000;
$color_light_black: #282828;
$color_error: #d32f2f;
$color_grey: #9d9ea1;
$color_light_grey: #eee;
$color_grey__blue: #edf2fa;
$color_white: #fff;
$color_white__blue: rgb(251, 253, 255);
$imagePlaceholderColor: #0001;
$title_color: #222222;
$select_background_color: rgba(229, 229, 229, 0.3);
$select_text_color: rgba(0, 0, 0, 0.87);
$green_text_color: #88b04b;

$fonts: "Roboto", sans-serif;

$shadow_btn: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
  0px 1px 10px 0px rgba(0, 0, 0, 0.12);

$box_shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);

$borderFilterItem: 1px solid rgb(229, 232, 235);

$widthPicture: 100%;
$left_side__bar: 312px;

$title_h1: 42px;
$title_h2: 30px;
$title_h3: 20px;
$title_h4: 16px;
$title_h5: 14px;
$title_h6: 12px;

$title_h1__margin: 30px;
$title_h2__margin: 25px;
$title_h3__margin: 20px;
$title_h4__margin: 15px;
$title_h5__margin: 10px;
$title_h6__margin: 5px;

$media_sm: 600px;
$media_md: 900px;
$media_lg: 1200px;
$media_xl: 1600px;
