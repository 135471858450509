@import "../../styles/variables";

.footer {
  padding: 30px 0;
  background-color: #d7d8d9;
  font-weight: 600;

  .footer_grid {
    display: flex;
    justify-content: space-between;
    .footer_address {
      list-style: none;
    }

    .footer_content {
      width: 450px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .footer_soc__icons {
    margin-top: 20px;
    margin-bottom: 20px;
    img {
      width: 30px;
    }
  }

  .footer_copy {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    .copyrights__text {
      margin-top: 10px;
      font-size: 11px;
    }

    .footer_copy__link {
      display: flex;
      grid-column-gap: 10px;

      a {
        color: $color_black;
        font-size: 11px;

        &:hover {
          color: $color_grey;
        }
      }
    }

  }
}
