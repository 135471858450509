@import "../../styles/variables";

.comments {
  .comments_list {
    display: flex;
    flex-direction: column;
    grid-row-gap: 10px;
  }

  .comments_buttons {
    display: flex;
    position: relative;
    margin-top: 20px;
  }

  .load_more {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -50px;
  }
}

.add_comment {
  display: flex;
  align-items: center;
  grid-column-gap: 10px;

  .icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #c2c2c2;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: box-shadow 0.3s;

    &:hover {
      -webkit-box-shadow: $shadow_btn;
      box-shadow: $shadow_btn;
    }

    .plus_svg {
      width: 50%;
      color: $color_white;
    }
  }
}
