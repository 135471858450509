@import "../../styles/variables.scss";

.location_autocomplete {
  position: relative;

  &__placeholder {
    position: absolute;
    font-size: 12px;
    top: 14px;
  }

  &__close_icon {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 5px;
    cursor: pointer;
  }

  .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer {
    display: none;
  }
  .css-1uccc91-singleValue {
    padding-right: 30px;
  }
  .css-1wa3eu0-placeholder {
    color: $select_text_color;
  }
}
