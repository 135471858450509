@import "../../styles/variables";

.artworks_explore_item_details {
  display: grid;
  grid-template-columns: 1fr 40%;
  justify-content: space-between;
  grid-template-rows: 100vh;
  max-height: 100vh;
  overflow: hidden;
  min-width: 100vw;
}

.rec-slider-container {
  margin: 0 !important;
}

.explore_item_details_right {
  display: grid;
  grid-template-rows: 1fr minmax(60px, auto);
  height: 100vh;
  overflow-y: scroll;

  .wrap {
    overflow-y: scroll;
    padding: 0 25px 15px;
  }

  .explore_item {
    margin-bottom: 25px;

    &__title {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 5px;
    }

    &__text {
      font-size: 14px;
    }
  }
}

.artwork_shows {
  &__card {
    display: flex;
  }

  &__card__grid {
    margin-left: 12px;
  }

  &__image {
    width: 100px;
    max-height: 85px;
    overflow: hidden;

    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
  }

  &__title {
    font-size: $title_h3;
    margin-bottom: 10px;
  }

  &__label {
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__sub__title {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 400;
    margin-top: 15px;
  }

  &__location {
    color: #9d9ea1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 400;
    margin-top: 5px;
  }

  .artwork_shows__wrapper {
    display: flex;
    flex-direction: column;
  }
}

.load_more__button {
  display: flex;
  justify-content: center;
  width: 100%;
  .load_more {
    color: #000000;
    margin-top: 10px;
  }
}



.artworks_action_buttons {
  display: flex;
  align-items: center;
  margin-top: 10px;
  grid-column-gap: 10px;

  .artwork_comments_icon {
    display: flex;
    align-items: center;
    cursor: pointer;

    .artwork_scans__comments {
      font-size: 12px;
    }

    img {
      width: 18px;
      margin-right: 5px;
    }
  }
}

.artwork_scans {
  display: flex;
  align-items: center;

  .artwork_scans__img {
    margin-right: 5px;
  }

  .artwork_scans__count {
    font-size: 12px;
    display: flex;
  }
}

.artwork_shares {
  display: flex;
  align-items: center;

  .artwork_shares_count {
    font-size: 12px;
  }
}

.artwork_suggestions {
  margin-top: 50px;

  &__title {
    margin-bottom: 20px;
  }
}

.light_box__wrap {
  padding: 26px 10px 70px;
  background-color: #222222;
  height: 100%;
  overflow: hidden;

  .main_image {
    height: 460px;
    margin-top: 35px;
  }
}
