.copy_link {
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding: 5px 10px;

  &:hover .tooltip {
    z-index: 1;
    opacity: 1;
  }

  .tooltip {
    z-index: -1;
    opacity: 0;
    transition: opacity .35s;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    color: #ffffff;
    padding: 4px;
    border-radius: 4px 6px;
    width: max-content;
    font-size: 14px;
  }
}

