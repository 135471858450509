.search_result {
  margin-top: 27px;

  &__title {
    font-size: 32px;
    font-weight: 500;
    position: relative;
    padding-left: 40px;
    overflow: auto;
    white-space: nowrap;
    text-overflow: ellipsis;

    .search_result__back-button {
      font-size: 30px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
}
