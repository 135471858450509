.upload_artwork_page {
  box-sizing: border-box;
  min-height: calc(100vh - 80px);
  padding-top: 15px;
  padding-bottom: 25px;

  &__title {
    display: flex;
    column-gap: 10px;
    margin-bottom: 50px;
    margin-top: 20px;
  }

  &__content {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 100px;
  }

  &__image_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 220px);
    background-color: #d9d9d9b9;
    position: relative;
  }

  &__input_file {
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 5;
  }

  &__artwork_image {
    position: absolute;
    left: 50%;
    top: 50%;
    max-height: 100%;
    max-width: 100%;
    transform: translate(-50%, -50%);
    opacity: 0.2;
  }

  &__image_section_title {
    position: absolute;
    bottom: 28px;
    left: 44px;
    font-weight: 500;
  }

  &__controls_section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__checkbox_wrapper {
    display: flex;
    flex-direction: column;
  }

  &__continue_button {
    float: right;
  }
}
