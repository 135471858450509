@import "../../styles/variables";

.art_inst_description {
  overflow: hidden;
  max-width: 60%;

  .art_inst_description__title {
    font-size: 16px;
    font-weight: 500;
    color: $color_black;
  }

  &__days_open {
    margin-top: 15px;
  }
  &__item {
    margin-top: 15px;

    &:first-child {
      margin-top: 25px;
    }
  }

  &__images {
    cursor: pointer;
    transition: box-shadow 0.35s;
    display: flex;
    max-width: 300px;

    .rec-swipable {
      display: flex;
      align-items: center;

      .rec-carousel-item {
        overflow: hidden;
      }
    }
  }

  &__text {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
  }

  &__image {
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 10%;
  }

  &_reg_link {
    color: #004bce;
    line-height: 50px;
    text-decoration: underline;
  }

  &__link {
    margin-top: 15px;
    height: fit-content !important;
    font-size: 14px;
    max-width: 450px;
    display: flex;
    flex-direction: row !important;
    align-items: center;

    .Image {
      height: 120px;
      background-size: contain;
      width: 40%;
    }

    .LowerContainer {
      width: 60%;
    }

  }

  .explore_item_details_right__more_info_button {
    margin-top: 15px;
  }

  .calendar_duration {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
}
