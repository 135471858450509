.location_filter {
  position: relative;
  &__input {
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
    .css-1wa3eu0-placeholder {
      color: grey;
    }
    .css-yk16xz-control,
    .css-1pahdxg-control {
      cursor: text;
      display: flex;
      align-items: center;
      background-color: rgb(255, 255, 255);
      border-radius: 10px;
      border: 1px solid rgb(229, 232, 235);
      padding: 6px 2px;
      margin-top: 10px;
      position: relative;
      box-shadow: none;
      padding-left: 25px;

      &:hover {
        border: 1px solid rgb(229, 232, 235);
      }
    }
  }
  &__search_icon {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translate(0, -50%);
    z-index: 5;
  }
}
