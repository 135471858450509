@import "../../styles/variables";

.btn {
  display: inline-block;
  border: none;
  border-radius: 4px;
  padding: 6px 16px;
  cursor: pointer;
  font-weight: 600;
  box-sizing: border-box;
  line-height: 1.75;
  text-transform: capitalize;
  transition: all .35s;

  &.bg_dark {
    background-color: $color_black;
    color: $color_white;

    &:hover {
      background-color: $color_light_black;
      box-shadow: $shadow_btn;
    }
  }

  &.bg_light {
    background-color: $color_white;
    color: $color_black;

    &:hover {
      background-color: #c2c2c2;
      box-shadow: $shadow_btn;
    }
  }

  &.bg_none {
    background-color: transparent;
  }

  &.text {
    background-color: transparent;
    padding: 3px 8px;
  }

  &.outlined {
    border: 1px solid $color_black;
    color: $color_grey;

    &:hover {
      border-color: transparent;
      background-color: $color_light_black;
      color: $color_white;
      box-shadow: $shadow_btn;
    }
  }

  &.outlined_dark {
    border: 1px solid $color_black;
    color: $color_black;

    &:hover {
      border-color: transparent;
      background-color: $color_light_black;
      color: $color_white;
      box-shadow: $shadow_btn;
    }
  }

  &.button {
    background-color: $color_white;

    &:hover {
      box-shadow: $shadow_btn;
    }
  }

  &.button_dark {
    background-color: $color_black;
    color: $color_white;

    &:hover {
      box-shadow: $shadow_btn;
    }
  }

  &.btn_disabled {
    color: $color_grey !important;
    background-color: $color_light_grey !important;
    cursor: auto;
  }
}

.add_institution {
  text-transform: uppercase;
}
