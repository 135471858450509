@import "../../styles/variables";

.items_not__found {
  border: 1px solid rgb(229, 232, 235);
  height: 220px;
  margin: 24px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-row-gap: 20px;

  .items_not__found__title {
    font-size: $title_h2;
  }

  .items_not__found__button {
    max-width: 300px;
  }
}