@import "../../styles/variables.scss";

.artist_page_item {
  width: 100%;
  min-height: 201px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
  box-sizing: border-box;
  padding: 0;
  cursor: pointer;

  &__link {
    width: 100%;
    height: 100%;
    min-height: 200px;
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
  }

  &__moca_text {
    color: #0000000f;
    font-size: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__background {
    position: absolute;
    object-position: center;
    object-fit: cover;
    height: 201px;
    width: 100%;
    top: 0;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 5;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
  }

  &__avatar {
    display: flex;
    align-items: center;
  }

  &__avatar_icon {
    margin-right: 11px;
  }

  &__name {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    padding-right: 5px;
    color: #ffffff;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
  }
  .artist_page_item__follow_button {
    background-color: rgba(252, 252, 252, 0.8);
    color: $title_color;
    border-radius: 4px;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    height: 32px;
    width: 90px;
    justify-self: center;
    z-index: 10;

    &:hover {
      background-color: $color_white;
    }
  }
}
